<template>
  <v-autocomplete
    v-model="values"
    :items="items"
    small-chips
    :label="label"
    :multiple="multiple"
    deletable-chips
    clearable
    :return-object="returnObject"
    dense
    autocomplete="off"
    autofill="false"
    :search-input.sync="search"
    @change="change"
  >
  <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip
                v-bind="attrs"
                :input-value="selected"
                color="secondary"
                label
                x-small
                dark
                @click="select"
                class="mb-1 pr-0 mt-1 mr-1 ml-0"
              >
                <span>{{ item.text || item}}</span>
                <v-btn
                  x-small
                  icon
                  @click.stop="
                  remove(item)
                "
                >
                  <v-icon> mdi-close-thick </v-icon>
                </v-btn>
              </v-chip>
</template>


</v-autocomplete>

  <!-- <v-autocomplete
      v-model="value"
      :items="items"
      :label="label"
      hide-selected
      :multiple="multiple"
      deletable-chips
      clearable
      :return-object="returnObject"
      dense
      autocomplete="off"
      autofill="false"
      :search="search"
    >
      <template v-slot:selection="{ attrs, item, select, selected }">
        <v-chip
          v-bind="attrs"
          :input-value="selected"
          color="secondary"
          label
          x-small
          @click="select"
          class="mb-1"
        >
          <span>{{ item.text || item }}</span>
          <v-btn x-small dark icon @click.stop="remove(item)">
            <v-icon> mdi-close-thick </v-icon>
          </v-btn>
        </v-chip>
      </template>
    </v-autocomplete> -->
</template>

<script>

let searchTimeout

export default {
  data() {
    return {
      search: "",
      values: null
    };
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String,Array,Object],
      default(){
          return null
      },
    },
  },
  watch:{
      value:{handler(val){
          this.values = val 
      },immediate: true,
      deep: true
    },
    search(){
        this.clearSearch(5)
    }
  },
    methods: {
        clearSearch(sec=0){
            clearTimeout(searchTimeout)
        searchTimeout = setTimeout(()=>{
            this.search = ''
        }, sec * 1000)  
        },
      change(){
        this.clearSearch(2)
        this.$emit('input', this.values)
      },
    remove(item) {
      if (this.multiple) {
        this.values = (this.values || []).filter((i) => i != item);
      } else {
        this.values = null;
      }
    },
  },
};
</script>