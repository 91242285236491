<template>
  <div class="d-flex">
    <v-card class="mr-2 flex-shrink-1">
      <v-card-text>
      <v-list dense
      style="max-height: calc(100vh - 150px)"
       class="overflow-y-auto"
      >
        <v-subheader>Group Mappings</v-subheader>
        <v-list-item-group>
          <draggable v-model="permissions.mapping" @end="savePermission">
            <v-list-item
              v-for="item in permissions.mapping"
              :key="item.groupId"
               class="pl-0"
            >
             <v-list-item-icon  class="mr-1">
                  <v-icon>mdi-key</v-icon>
                </v-list-item-icon>
              <v-list-item-content class="pa-0 ma-0">
                <v-list-item-title
                  >{{ item.weight }}) {{ item.name }}</v-list-item-title
                >
                <v-list-item-subtitle>
                  <v-chip label x-small color="primary" class="mr-1">
                    {{ item.role.toUpperCase() }}
                  </v-chip>
                  <v-chip
                    v-if="item.allClients"
                    label
                    x-small
                    dark
                    color="green"
                  >
                    ***All Clients
                  </v-chip>
                  <v-chip
                    v-else
                    v-for="client in item.clients"
                    :key="client"
                    label
                    x-small
                    color="secondary"
                    class="mr-1"
                  >
                    {{ client }}
                  </v-chip>
                </v-list-item-subtitle>
              </v-list-item-content>
                <v-btn icon @click="edit(item)">
                    <v-icon small>mdi-note-edit-outline</v-icon>
                </v-btn>
            </v-list-item>
          </draggable>
        </v-list-item-group>
        </v-list
      >
      </v-card-text>
    </v-card>
    <v-card class="flex-grow-1">
      <v-card-text>
        <v-form ref="permissionForm" autocomplete="off">
          <v-autocomplete
            v-model="permission.groupId"
            :items="filteredAdGroups"
            label="AD Group"
            :autofocus="true"
            autofill
            hide-selected
            clearable
            open-on-clear
            item-text="displayName"
            item-value="id"
            dense
            autocomplete="off"
          >
          </v-autocomplete>

          <v-combobox
            chips
            dense
            label="Role"
            :items="roles"
            v-model.trim="permission.role"
            deletable-chips
          >
            <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip
                v-bind="attrs"
                :input-value="selected"
                close
                label
                small
                @click="select"
                color="primary"
                @click:close="removeChip(item, 'role')"
              >
                <span>{{ item }}</span>
              </v-chip>
            </template>
          </v-combobox>

          <v-checkbox v-model="permission.allClients" label="All Clients"></v-checkbox>

          <v-autocomplete
            v-model="permission.clients"
            :items="clients"
            label="Clients"
            clearable
            open-on-clear
            item-text="name"
            item-value="id"
            dense
            chips
            multiple
            deletable-chips
            v-if="!permission.allClients"
            hide-selected
          >
            <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip
                v-bind="attrs"
                :input-value="selected"
                close
                label
                small
                @click="select"
                color="secondary"
                @click:close="removeChip(item.id, 'clients')"
              >
                <span>{{ item.name }}</span>
              </v-chip>
            </template>
          </v-autocomplete>

          <!-- 
      <FancySelect
        label="Clients"
        :items="clients"
        item-text="name"
        item-value="id"
        v-model="permission.clients"
        multiple
        dense
        v-if="!allClients"
      /> -->
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="cancel" text>Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="preSavePermission" color="primary"
          >Save Permission</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import firebase from "../../firebase";
const functions = firebase.functions();
import draggable from "vuedraggable";
// import FancySelect from "../../components/fields/FancySelect.vue";
import { mapState } from "vuex";
const db = firebase.firestore();
export default {
  data() {
    return {
      originalPermissions: {},
      permissions: {
        mapping: [],
      },
      adGroups: [],
      permission: {
        name: "",
        groupId: "",
        role: "",
        clients: [],
        allClients: false,
        weight: 0,
        id: null,
        created: null,
        updated: null,
      },
    };
  },
  computed: {
    ...mapState(["clients"]),
    filteredAdGroups() {
      // return this.adGroups;
      let groupIds = this.permissions.mapping.map(
        (permission) => permission.groupId
      );
      // return this.adGroups.filter(group=>groupIds.indexOf(group.id)<0)
      return this.adGroups.map((group) => {
        return {
          id: group.id,
          displayName: group.displayName,
          disabled: groupIds.indexOf(group.id) > -1,
        };
      });
    },
    roles() {
      if (!this.permissions || !this.permissions.mapping) {
        return [];
      }
      return [
        ...new Set(
          ['admin','dev',
          ...this.permissions.mapping.map((permission) => permission.role)
    ]
        ),
      ];
    },
    allClients() {
      return (
        ["dev", "admin", "tech", "exec", "finance", "wfm", "hr"].indexOf(
          (this.permission.role || "").trim().toLowerCase()
        ) > -1
      );
    },
  },
  watch: {
    "$store.state.permissions": {
      handler(val) {
        if (!val) {
          return;
        }
        this.originalPermissions = JSON.parse(JSON.stringify(val));
        let defaultSettings = {
          mapping: [],
          updated: null,
          updatedBy: null,
        };

        this.permissions = { ...defaultSettings, ...JSON.parse(JSON.stringify(val)) };
        this.permissions.mapping.sort((a, b) => {
          return a.weight < b.weight ? -1 : a.weight > b.weight ? 1 : 0;
        });
        console.log(this.permissions, val);
        if (this.permissions.mappings) {
          this.permissions.mapping = this.permissions.mappings;
          delete this.permissions.mappings;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    removeChip(item, key) {
      let selection = this.permission[key];
      if (Array.isArray(selection)) {
        selection = selection.filter((i) => i != item);
      } else {
        selection = null;
      }
      this.permission[key] = selection;
    },
    edit(item) {
      this.permission = JSON.parse(JSON.stringify(item));
    },
    async saveRevision() {
      let id = new Date().toString();
      let permissionDoc = await db
        .collection("config")
        .doc("permissions")
        .collection("revisions")
        .doc(id);
      await permissionDoc.set(this.originalPermissions);
    },
    async savePermission() {
      await this.saveRevision();
      this.permissions.mapping.forEach((item, index) => {
        item.weight = index;
      });

      // console.log(
      //   "SAVE",
      //   this.permissions.mapping.map((item) => item.weight)
      // );
      let permissionDoc = await db.collection("config").doc("permissions");
      this.permissions.updated = new Date();
      this.permissions.updatedBy = "Me";
      await permissionDoc.set(this.permissions);
    },
    async preSavePermission() {
      if (!this.permission.created) {
        this.permission.weight = this.permissions.mapping
          ? this.permissions.mapping.length
          : 0;
        this.permission.created = new Date();
      }
      this.permission.name = this.adGroups.filter(
        (group) => group.id == this.permission.groupId
      )[0].displayName;
      this.permission.allClients = this.allClients;
      if (this.permission.allClients) {
        this.permission.clients = [];
      }
      this.permission.role = this.permission.role.trim().toLowerCase();
      this.permission.updated = new Date();
      this.permissions.mapping = (this.permissions.mapping || []).filter(
        (group) => group.groupId !== this.permission.groupId
      );

      this.permissions.mapping.push({ ...this.permission });

      this.permissions.mapping = [
        ...this.permissions.mapping.sort((a, b) =>
          a.weight < b.weight ? -1 : a.weight > b.weight ? 1 : 0
        ),
      ];


      this.savePermission();

      this.$refs.permissionForm.reset();
    },
    cancel() {
      this.$refs.permissionForm.reset();
    },
    async getAllAdGroups() {
      let getAllAdGroups = functions.httpsCallable("getAllAdGroups");
      let resp = await getAllAdGroups();
      console.log(resp);
      this.adGroups = resp.data.sort((a,b)=>{
        return a.displayName.localeCompare(b.displayName)
      });
    },
  },
  components: {
    // FancySelect,
    draggable,
  },
  mounted() {
    this.$nextTick(() => {
      this.getAllAdGroups();
    });
  },
};
</script>

<style>
</style>