<template>
  <div class="d-flex">
    <v-card class="flex-grow-0 mr-2">
      <v-card-text>
        <v-list dense>
          <v-subheader>Clients</v-subheader>
          <v-list-item-group>
            <v-list-item
              v-for="client in clients"
              :key="client.id"
              class="pl-0"
            >
              <v-list-item-icon class="mr-1">
                <v-icon>mdi-star</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="pa-0 ma-0">
                <v-list-item-title>
                  {{ client.name }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon @click="edit(client)">
                <v-icon small>mdi-note-edit-outline</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <pre></pre>
      </v-card-text>
    </v-card>
    <v-card class="flex-grow-1">
      <v-card-text>
        <v-form autocomplete="off" ref="clientForm">
          <v-text-field
            label="Client Name"
            v-model.trim="client.name"
            dense
          ></v-text-field>
          <v-text-field
            dense
            disabled
            v-model="client.id"
            label="Client ID"
          ></v-text-field>
          <v-combobox
            v-model="client.clientDomains"
            chips
            clearable
            dense
            label="Email Domains"
            multiple
            autocomplete="off"
          >
            <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip
                v-bind="attrs"
                :input-value="selected"
                label
                color="secondary"
                small
                @click="select"
                class="pr-1"
              >
                <span>{{ item }}</span>

                <v-btn
                  x-small
                  dark
                  icon
                  @click.stop="removeClientDomain(item)"
                >
                  <v-icon> mdi-close-thick </v-icon>
                </v-btn>

              </v-chip>
            </template>
          </v-combobox>

          <v-autocomplete
            v-model="client.brightPattern.services"
            :items="brightPattern.services"
            label="BrightPattern Services"
            autofill
            hide-selected
            chips
            multiple
            deletable-chips
            clearable
            item-text="Name"
            item-value="Name"
            dense
            autocomplete="off"
          >
            <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip
                v-bind="attrs"
                :input-value="selected"
                color="secondary"
                label
                :x-small="client.brightPattern.services.length > 5"
                :small="client.brightPattern.services.length <= 5"
                @click="select"
                class="mb-1 pr-1"
              >
                <span>{{ item.Name }}</span>
                <v-btn
                  x-small
                  dark
                  icon
                  @click.stop="
                    client.brightPattern.services =
                      client.brightPattern.services.filter(
                        (i) => i != item.Name
                      )
                  "
                >
                  <v-icon> mdi-close-thick </v-icon>
                </v-btn>
              </v-chip>
            </template>
          </v-autocomplete>

          <v-autocomplete
            v-model="client.brightPattern.teams"
            :items="brightPattern.teams"
            label="BrightPattern Teams"
            autofill
            hide-selected
            chips
            multiple
            deletable-chips
            clearable
            item-text="Name"
            item-value="Name"
            dense
            autocomplete="off"
          >
            <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip
                v-bind="attrs"
                :input-value="selected"
                color="secondary"
                label
                small
                @click="select"
                class="mb-1"
              >
                <span>{{ item.Name }}</span>
                <v-btn
                  x-small
                  dark
                  icon
                  @click.stop="
                  client.brightPattern.teams =
                    client.brightPattern.teams.filter((i) => i != item.Name)
                "
                >
                  <v-icon> mdi-close-thick </v-icon>
                </v-btn>
              </v-chip>
            </template>
          </v-autocomplete>

          <AutoComplete v-model="client.platforms" :items="platforms" multiple label="Platforms"  ></AutoComplete>
          <AutoComplete v-model="client.maintenanceNotice" :items="maintenanceNoticeUsers" multiple label="Maintenance Notification"  returnObject ></AutoComplete>

        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="cancel">Cancel</v-btn>
        <v-btn @click="getBPOptions">getBPOptions</v-btn>
        <v-spacer />
        <v-btn color="primary" @click="saveClient">Save</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import camelCase from "lodash.camelcase";
import firebase from "../../firebase";
import platforms from "../../utils/platforms";
import AutoComplete from "../../components/fields/AutoComplete.vue";

const functions = firebase.functions()
const db = firebase.firestore()
let getAllUsers = functions.httpsCallable("getAllUsers")

const defaultClient = {
  brightPattern: {
    services: [],
    teams: [],
  },
  clientDomains: [],
  id: "",
  name: "",
  zendesks: [
    {
      brands: [],
      fields: [],
      forms: [],
      subdomain: "",
    },
  ],
  platforms:[],
  maintenanceNotice: [
    
  ]
};

export default {
  data() {
    return {
      brightPattern: {
        services: [],
        teams: [],
      },
      client: JSON.parse(JSON.stringify(defaultClient)),
      contacts: [],
      platforms,
    };
  },
  watch: {
    "client.name"(val) {
      if (val) {
        //check if original exists
        if (!this.client.created) {
          this.client.id = camelCase(val);
        }
      }
      console.log("CLIENT NAME", this.client);
    },
  },
  computed: {
    ...mapState(["clients"]),
    maintenanceNoticeUsers(){
      let domains = this.client.clientDomains.map(domain=>domain.toLowerCase())
      console.log({domains})
      if (domains.length){
        console.log(this.contacts)
        return this.contacts.filter(user=>user.domain === 'vipdeskconnect.com' || domains.indexOf(user.domain)>-1)
      } else {
        return this.contacts
      }

    }
  },
  mounted() {
    this.getBPOptions();
    this.getAllUsers()
    // this.$nextTick(()=>{
    //   this.
    // })
  },
  methods: {
    async getAllUsers(){
      let result = await getAllUsers()
      let users = result.data.filter(user=>user.mail).map(user=>{
        console.log(user)
        let domain = user.mail.split('@')[1].toLowerCase().trim()
        return {
        text: `${user.displayName} (${domain === 'vipdeskconnect.com' ? user.jobTitle : domain})`,
        displayName: user.displayName,
        address: user.mail,
        value: user.mail,
        domain,
      }
    })
      this.contacts = users
    },
    cancel() {
      this.client = JSON.parse(JSON.stringify(defaultClient));
      this.$refs.clientForm.reset();
    },
    edit(item) {
      console.log(item);
      this.client = {
        ...JSON.parse(JSON.stringify(defaultClient)),
        ...JSON.parse(JSON.stringify(item)),
      };
    },
    removeClientDomain(item) {
      this.client.clientDomains.splice(
        this.client.clientDomains.indexOf(item),
        1
      );
      this.client.clientDomains = [...this.client.clientDomains];
    },
    async getBPOptions() {
      let getBPOptions = functions.httpsCallable("getBPOptions");
      let resp = await getBPOptions();
      console.log(resp);
      this.brightPattern = resp.data;
      // this.brightPattern.services = resp.data.services.map((item) => item.Name);
      // this.brightPattern.teams = resp.data.teams.map((item) => item.Name);
    },
    async saveClient() {
      let id = this.client.id || undefined;
      this.client.created = this.client.created || new Date();
      this.client.updated = this.client.updated || new Date();
      let clientDoc = await db.collection("clients").doc(id);
      id = clientDoc.id;
      this.client.id = id;
      this.client.clientDomains = this.client.clientDomains.map((domain) =>
        domain.toLowerCase()
      );
      await clientDoc.set(this.client);
      this.client = JSON.parse(JSON.stringify(defaultClient));

      this.$refs.clientForm.reset();
    },
  },
  components: {
    AutoComplete
  },
};
</script>

<style>
</style>