<template>
  <div class="d-flex">
    <v-card class="flex-shrink-1 mr-2">
      <v-card-text>
        <v-list dense>
          <v-subheader>Dashboards and Reports</v-subheader>
          <v-list-item-group>
            <v-list-item v-for="dashboard in dashboards" :key="dashboard.id"  class="pl-0">
              <v-list-item-icon  class="mr-1">
                <v-icon>mdi-chart-box</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="pa-0 ma-0">
                <v-list-item-title>
                  {{ dashboard.name }}
                </v-list-item-title>
              </v-list-item-content>
                <v-btn icon @click="edit(dashboard)">
                  <v-icon small>mdi-note-edit-outline</v-icon>
                </v-btn>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
    </v-card>
    <v-card class="flex-grow-1">
      <v-card-text>
        <v-form ref="dashboardForm" dense>
          <v-text-field
            dense
            label="Dashboard Name"
            v-model="dashboard.name"
          ></v-text-field>
          <v-textarea
            dense
            label="Description"
            rows="1"
            v-model="dashboard.description"
          ></v-textarea>
          <div class="d-flex">
            <v-combobox
              dense
              class="mr-5"
              label="Category"
              :items="categories"
              v-model.trim="dashboard.category"
            >
            </v-combobox>

            <v-select
              class="mr-5"
              label="Type"
              dense
              :items="['question', 'dashboard']"
              v-model="dashboard.metabaseType"
            ></v-select>
            <v-text-field
                          class="mr-5"
              label="ID"
              type="number"
              dense
              v-model="dashboard.metabaseId"
            ></v-text-field>
                        <v-text-field
              label="Auto Refresh"
              type="number"
              dense
              v-model="dashboard.autoRefresh"
            ></v-text-field>

          </div>

          <RoleSelect multiple v-model="dashboard.access.roles" />
          <ClientSelect multiple v-model="dashboard.access.clients" />

          <div class="d-flex">
            <v-select
              label="Filters"
              dense
              :items="filters"
              item-text="name"
              return-object
              v-model="filterSelector"
              @change="selectFilter"
              clearable
              class="flex-grow-0"
            ></v-select>
            <v-card class="flex-grow-1 ml-3">
              <v-card-text>
                <draggable v-model="dashboard.filters" @end="reorder">
                  <v-toolbar
                    dense
                    v-for="filter in dashboard.filters"
                    :key="filter.id"
                    flat
                    :color="
                      filter.weight % 2 == 0 ? 'grey lighten-3' : undefined
                    "
                  >
                    <span class="mr-2">{{ filter.weight }}) </span>

                    <!-- <v-toolbar-items class="flex-grow-1 justify-space-between"> -->
                    <v-chip
                      style="min-width: 150px"
                      label
                      class="mr-4"
                      :dark="filter.weight % 2 == 0"
                    >
                      <v-icon class="mr-2">{{ filter.icon }}</v-icon>
                      <span> {{ filter.name }}</span>
                    </v-chip>
                    <label class="pr-2">Label:</label>
                    <v-text-field
                      class="flex-grow-1"
                      hide-details
                      single-line
                      v-model="filter.label"
                    ></v-text-field>
                    <label class="pr-2">Key:</label>
                    <v-text-field
                      class="flex-grow-1"
                      hide-details
                      single-line
                      v-model="filter.key"
                    ></v-text-field>
                    <template
                      v-if="
                        filter.component === 'DateFilter' &&
                        filter.options.defaultValue !== undefined
                      "
                    >
                      <v-spacer />

                      <label class="pr-2">Default:</label>
                      <v-select
                        :items="defaultDays(filter.options.maxRange)"
                        v-model="filter.options.defaultValue"
                        single-line
                        hide-details
                      ></v-select>
                    </template>
                    <template
                      v-else-if="filter.options.defaultValue !== undefined"
                    >
                      <v-spacer />

                      <label class="pr-2">Default:</label>
                      <v-text-field
                        v-model="filter.options.defaultValue"
                        single-line
                        hide-details
                        :type="
                          filter.component === 'NumberFilter'
                            ? 'number'
                            : undefined
                        "
                      ></v-text-field>
                    </template>
                    <template v-if="filter.options.maxRange !== undefined">
                      <v-spacer />
                      <label class="pr-2">Max Range:</label>

                      <v-text-field
                        hide-details
                        single-line
                        :suffix="`day${filter.options.maxRange > 1 ? 's' : ''}`"
                        :hide-spin-buttons="true"
                        v-model="filter.options.maxRange"
                        type="number"
                      ></v-text-field>
                    </template>
                    <template v-if="filter.options.multiple !== undefined">
                      <v-spacer />
                      <label class="pr-2">Allow Multiple:</label>

                      <v-checkbox
                        v-model="filter.options.multiple"
                        hide-details
                        single-line
                      />
                    </template>
                    <template v-if="filter.options.preselectAll !== undefined">
                      <v-spacer />
                      <label class="pr-2">Preselect All:</label>

                      <v-checkbox
                        v-model="filter.options.preselectAll"
                        hide-details
                        single-line
                      />
                    </template>
                    <v-btn
                      color="red"
                      icon
                      small
                      @click="
                        dashboard.filters = dashboard.filters.filter(
                          (item) => item != filter
                        );
                        reorder();
                      "
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <!-- </v-toolbar-items> -->
                  </v-toolbar>
                </draggable>
              </v-card-text>
            </v-card>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="clear">Cancel</v-btn>
        <v-btn v-if="dashboard.id" color="red" @click="deleteDashboard" icon>
          <v-icon> mdi-delete </v-icon>
        </v-btn>
        <v-spacer />
        <v-btn color="primary" @click="saveDashboard">Save</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import RoleSelect from "../../components/fields/RoleSelect.vue";
import ClientSelect from "../../components/fields/ClientSelect.vue";
import draggable from "vuedraggable";
import firebase from "../../firebase";
const db = firebase.firestore();

const defaultDashboard = {
  name: null,
  description: null,
  access: {
    clients: [],
    roles: {},
  },
  metabaseId: null,
  metabaseType: null,
  filters: [],
  autoRefresh: null
};

export default {
  data() {
    return {
      filterSelector: "",
      filters: [
        {
          name: "Date Filter",
          label: "Select Date",
          component: "DateFilter",
          key: "date_filter",
          options: {
            defaultValue: "past1months",
            maxRange: 1,
          },
          icon: "mdi-calendar",
        },
        {
          name: "Current User",
          component: "CurrentUserFilter",
          key: "user_filter",
          options: {},
          icon: "mdi-account",
        },
        {
          name: "Text Filter",
          label: "Enter Text",
          component: "TextFilter",
          key: "text_filter",
          icon: "mdi-form-textbox",

          options: {
            defaultValue: null,
          },
        },
        {
          name: "Number Filter",
          label: "Enter Number",

          component: "NumberFilter",
          key: "number_filter",
          icon: "mdi-counter",

          options: {
            defaultValue: null,
          },
        },
        {
          name: "BP Service Filter",
          label: "Select BP Service",

          component: "BPServiceFilter",
          key: "bp_service_filter",
          icon: "mdi-phone-classic",

          options: {
            multiple: true,
            preselectAll: true,
          },
        },
        {
          key: "bp_team_filter",
          name: "BP Team Filter",
          label: "Select BP Team",

          component: "BPTeamFilter",
          icon: "mdi-account-group",

          options: {
            multiple: true,
          },
        },
        {
          name: "Zendesk Filter",
          label: "Select Zendesk",

          component: "ZendeskFilter",
          icon: "mdi-view-dashboard",

          key: "zendesk_filter",
          options: {
            multiple: false,
          },
        },
      ],
      dashboard: JSON.parse(JSON.stringify(defaultDashboard)),
    };
  },
  computed: {
    ...mapState(["dashboards"]),
    categories() {
      return [
        ...new Set(
          (this.dashboards || []).map((dashboard) => dashboard.category)
        ),
      ];
    },
  },
  components: {
    RoleSelect,
    ClientSelect,
    draggable,
  },
  methods: {
    async deleteDashboard() {
      if (!this.dashboard.id) {
        return;
      }
      let doc = await db.collection("dashboards").doc(this.dashboard.id);
      await doc.delete();
      this.clear();
    },
    edit(dashboard) {
      this.dashboard = {
        ...JSON.parse(JSON.stringify(defaultDashboard)),
        ...JSON.parse(JSON.stringify(dashboard)),
      };
    },
    defaultDays(val) {
      let dayOptions = [
        {
          text: "Yesterday",
          value: "past1day",
        },
        {
          text: "Today",
          value: "currentday",
        },
      ];

      let rangeOptions = [
        {
          text: "Last Week",
          value: "past1week",
        },
        {
          text: "thisWeek",
          value: "currentIsoWeek",
        },
        {
          text: "Last Month",
          value: "past1month",
        },
        {
          text: "This Month",
          value: "currentMonth",
        },
      ];
      return val === 1
        ? dayOptions
        : val > 1
        ? [...dayOptions, ...rangeOptions]
        : [];
    },
    selectFilterKey(key, original, int = 0) {
      if (!original) {
        original = key;
      }
      console.log("CHECK KEY", key, original);
      let exists = this.dashboard.filters.filter(
        (filter) => filter.key === key
      );
      if (exists.length) {
        int++;
        let newKey = `${original || key}_${exists.length + int}`;
        return this.selectFilterKey(newKey, original, int);
      }
      return key;
    },
    reorder() {
      console.log("REORDER");
      this.dashboard.filters.forEach((filter, index) => {
        console.log(filter, index);
        filter.weight = index;
      });
    },
    selectFilter() {
      if (!this.filterSelector) {
        return;
      }
      let selected = JSON.parse(JSON.stringify(this.filterSelector));
      selected.key = this.selectFilterKey(selected.key);
      selected.weight = this.dashboard.filters.length;
      console.log("GOT IT ", selected);
      this.dashboard.filters.push(selected);
      setTimeout(() => {
        this.filterSelector = "";
      }, 10);
    },
    async saveDashboard() {
      let id = this.dashboard.id || undefined;
      this.dashboard.created = this.dashboard.created || new Date();
      this.dashboard.updated = this.dashboard.updated || new Date();
      // this.page.sections = this.page.sections ? JSON.parse(this.page.sections) : {}
      let doc = await db.collection("dashboards").doc(id);
      this.dashboard.id = doc.id;
      await doc.set(this.dashboard);
      this.clear();
    },
    clear() {
      this.dashboard = JSON.parse(JSON.stringify(defaultDashboard));
      this.$refs.dashboardForm.reset();
    },
  },
};
</script>
