<template>
    <v-card >
      <v-tabs
        v-model="tab"
        dark
        show-arrows
        grow
        dense
      >
        <v-tabs-slider color="teal lighten-3"></v-tabs-slider>

        <v-tab to="/admin/users"> Users </v-tab>
        <v-tab to="/admin/bookmarks"> Bookmarks </v-tab>
        <v-tab to="/admin/dashboards"> Dashboards </v-tab>
        <v-tab to="/admin/phone-schedules"> Phone Schedules </v-tab>
        <v-tab to="/admin/pages"> Pages </v-tab>
        <v-tab to="/admin/clients"> Clients </v-tab>
        <v-tab to="/admin/permissions"> Permissions </v-tab>
        <v-tab to="/admin/data-import"> Data Import </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class="pa-2">
        <v-tab-item value="/admin/users">
          <v-card color="basil" flat>
            <v-card-text>Users Tab?</v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item value="/admin/bookmarks">
          <v-card color="basil" flat>
            <Bookmarks />
          </v-card>
        </v-tab-item>
        <v-tab-item value="/admin/dashboards">
          <v-card color="basil" flat>
            <Dashboards/>
          </v-card>
        </v-tab-item>
        <v-tab-item value="/admin/phone-schedules">
          <v-card color="basil" flat>
            <v-card-text>Schedules Tab?</v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item value="/admin/pages">
          <v-card color="basil" flat>
            <v-card-text>
              <Pages />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item value="/admin/clients">
          <v-card color="basil" flat>
            <v-card-text>
              <Clients />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item value="/admin/permissions">
          <v-card color="basil" flat>
            <v-card-text>
              <Permissions />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item value="/admin/data-import">
          <v-card color="basil" flat>
            <v-card-text>
              <DataImport />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
</template>

<script>


import Pages from "./Pages.vue";
import Clients from "./Clients.vue";
import Bookmarks from "./Bookmarks.vue";
import Permissions from "./Permissions.vue";
import Dashboards from "./Dashboards.vue";
import DataImport from "./DataImport.vue";
export default {
  data() {
    return {
      tab: null,
    };
  },
  components: {
    Pages,
    Clients,
    Bookmarks,
    Permissions,
    Dashboards,
    DataImport
  },
};
</script>

<style>
</style>