<template>
  <v-select
    label="Clients"
    :items="clients"
    item-text="name"
    item-value="id"
    :multiple="multiple"
    dense
    v-model="selected"
    @change="change"
    hide-selected
        clearable
    deletable-chips
  >
    <template v-slot:selection="{ item }">
      <v-chip x-small label class="pr-1" color="secondary">
        <span>{{ item.name }}</span>
<v-btn x-small dark icon @click.stop="selected=selected.filter(i=>i!=item.id)"  >
      <v-icon >
        mdi-close-thick
      </v-icon>
      </v-btn>



      </v-chip>

    </template>


  </v-select>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      selected: null,
    };
  },
  props: ["multiple","value"],
  //   props: {
  //     multiple: {
  //       default: true,
  //       type: Boolean,
  //     },
  //   },
  watch:{value: {
    immediate: true,
    handler(val) {
      console.log({ val });
      this.selected = val;
    },
  }
  },
  computed: {
    ...mapState(["clients"]),
  },
  methods: {
    change() {
      console.log("change");
      this.$emit("input", this.selected);
    },
  },
};
</script>

<style>
</style>