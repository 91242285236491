var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex"},[_c('v-card',{staticClass:"flex-grow-0 mr-2"},[_c('v-card-text',[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',[_vm._v("Clients")]),_c('v-list-item-group',_vm._l((_vm.clients),function(client){return _c('v-list-item',{key:client.id,staticClass:"pl-0"},[_c('v-list-item-icon',{staticClass:"mr-1"},[_c('v-icon',[_vm._v("mdi-star")])],1),_c('v-list-item-content',{staticClass:"pa-0 ma-0"},[_c('v-list-item-title',[_vm._v(" "+_vm._s(client.name)+" ")])],1),_c('v-list-item-icon',{on:{"click":function($event){return _vm.edit(client)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-note-edit-outline")])],1)],1)}),1)],1),_c('pre')],1)],1),_c('v-card',{staticClass:"flex-grow-1"},[_c('v-card-text',[_c('v-form',{ref:"clientForm",attrs:{"autocomplete":"off"}},[_c('v-text-field',{attrs:{"label":"Client Name","dense":""},model:{value:(_vm.client.name),callback:function ($$v) {_vm.$set(_vm.client, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"client.name"}}),_c('v-text-field',{attrs:{"dense":"","disabled":"","label":"Client ID"},model:{value:(_vm.client.id),callback:function ($$v) {_vm.$set(_vm.client, "id", $$v)},expression:"client.id"}}),_c('v-combobox',{attrs:{"chips":"","clearable":"","dense":"","label":"Email Domains","multiple":"","autocomplete":"off"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({staticClass:"pr-1",attrs:{"input-value":selected,"label":"","color":"secondary","small":""},on:{"click":select}},'v-chip',attrs,false),[_c('span',[_vm._v(_vm._s(item))]),_c('v-btn',{attrs:{"x-small":"","dark":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.removeClientDomain(item)}}},[_c('v-icon',[_vm._v(" mdi-close-thick ")])],1)],1)]}}]),model:{value:(_vm.client.clientDomains),callback:function ($$v) {_vm.$set(_vm.client, "clientDomains", $$v)},expression:"client.clientDomains"}}),_c('v-autocomplete',{attrs:{"items":_vm.brightPattern.services,"label":"BrightPattern Services","autofill":"","hide-selected":"","chips":"","multiple":"","deletable-chips":"","clearable":"","item-text":"Name","item-value":"Name","dense":"","autocomplete":"off"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({staticClass:"mb-1 pr-1",attrs:{"input-value":selected,"color":"secondary","label":"","x-small":_vm.client.brightPattern.services.length > 5,"small":_vm.client.brightPattern.services.length <= 5},on:{"click":select}},'v-chip',attrs,false),[_c('span',[_vm._v(_vm._s(item.Name))]),_c('v-btn',{attrs:{"x-small":"","dark":"","icon":""},on:{"click":function($event){$event.stopPropagation();_vm.client.brightPattern.services =
                    _vm.client.brightPattern.services.filter(
                      function (i) { return i != item.Name; }
                    )}}},[_c('v-icon',[_vm._v(" mdi-close-thick ")])],1)],1)]}}]),model:{value:(_vm.client.brightPattern.services),callback:function ($$v) {_vm.$set(_vm.client.brightPattern, "services", $$v)},expression:"client.brightPattern.services"}}),_c('v-autocomplete',{attrs:{"items":_vm.brightPattern.teams,"label":"BrightPattern Teams","autofill":"","hide-selected":"","chips":"","multiple":"","deletable-chips":"","clearable":"","item-text":"Name","item-value":"Name","dense":"","autocomplete":"off"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                    var attrs = ref.attrs;
                    var item = ref.item;
                    var select = ref.select;
                    var selected = ref.selected;
return [_c('v-chip',_vm._b({staticClass:"mb-1",attrs:{"input-value":selected,"color":"secondary","label":"","small":""},on:{"click":select}},'v-chip',attrs,false),[_c('span',[_vm._v(_vm._s(item.Name))]),_c('v-btn',{attrs:{"x-small":"","dark":"","icon":""},on:{"click":function($event){$event.stopPropagation();_vm.client.brightPattern.teams =
                  _vm.client.brightPattern.teams.filter(function (i) { return i != item.Name; })}}},[_c('v-icon',[_vm._v(" mdi-close-thick ")])],1)],1)]}}]),model:{value:(_vm.client.brightPattern.teams),callback:function ($$v) {_vm.$set(_vm.client.brightPattern, "teams", $$v)},expression:"client.brightPattern.teams"}}),_c('AutoComplete',{attrs:{"items":_vm.platforms,"multiple":"","label":"Platforms"},model:{value:(_vm.client.platforms),callback:function ($$v) {_vm.$set(_vm.client, "platforms", $$v)},expression:"client.platforms"}}),_c('AutoComplete',{attrs:{"items":_vm.maintenanceNoticeUsers,"multiple":"","label":"Maintenance Notification","returnObject":""},model:{value:(_vm.client.maintenanceNotice),callback:function ($$v) {_vm.$set(_vm.client, "maintenanceNotice", $$v)},expression:"client.maintenanceNotice"}})],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.cancel}},[_vm._v("Cancel")]),_c('v-btn',{on:{"click":_vm.getBPOptions}},[_vm._v("getBPOptions")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.saveClient}},[_vm._v("Save")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }