<template>
  <div class="d-flex">
    <v-card class="flex-shrink-1 mr-2">
      <v-card-text>
        <v-list dense>
          <v-subheader>Pages</v-subheader>
          <v-list-item-group>
            <draggable v-model="pagesDraft" @end="reorder">
              <v-list-item v-for="item in allPages" :key="item.path" class="pl-0">
                <v-list-item-icon class="mr-1">
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon  @click="edit(item)">
                    <v-icon small>mdi-note-edit-outline</v-icon>
                </v-list-item-icon>

                <!-- <v-list-item-action>
                  <v-btn icon @click="edit(item)">
                    <v-icon>mdi-note-edit</v-icon>
                  </v-btn>
                </v-list-item-action> -->
              </v-list-item>
              <!-- 
        <v-list-item v-for="page in pagesDraft" :key="page.path">
          <v-list-item-content>
            <v-list-item-title>
            <v-icon x-small>{{ page.icon }}</v-icon>
            <span>
              {{ page.title || "no title" }}
            </span>
            </v-list-item-title>
          </v-list-item-content>
        <v-list-item-action class="ma-0">
          <v-btn icon x-small color="black">
            <v-icon>{{ page.weight }}</v-icon>
          </v-btn>
        </v-list-item-action>
        </v-list-item> -->
            </draggable>
          </v-list-item-group>
        </v-list>
      </v-card-text>
    </v-card>
    <v-card class="flex-grow-1">
      <v-card-text>
        <v-form ref="pageForm">
          <v-text-field
            label="ID"
            v-model="page.id"
            :disabled="page.updated !== undefined"
          ></v-text-field>
          <v-text-field label="Title" v-model="page.name"></v-text-field>
          <v-text-field label="Icon" v-model="page.icon"></v-text-field>
          <!-- <Roles label="Roles" v-model="page.access.roles"></Roles> -->
          <RoleSelect label="Roles" v-model="page.access.roles" multiple></RoleSelect>
          <v-text-field label="Path" v-model="page.path"></v-text-field>
          <VJsoneditor
            label="Sections (JSON)"
            v-model="page.sections"
            :options="{
              mode: 'code',
              mainMenuBar: false,
              navigationBar: false,
            }"
            :plus="false"
            height="400px"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="reset">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="createPage" color="primary">Create</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import VJsoneditor from "v-jsoneditor";
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
// import Roles from "../../components/Roles.vue";
import RoleSelect from "../../components/fields/RoleSelect";

import firebase from "../../firebase";
const db = firebase.firestore();

export default {
  data() {
    return {
      page: {
        id: "",
        name: "",
        icon: "",
        path: "",
        weight: 99,
        access: {
          roles: {
            "dev": true,
            "admin": true
          },
        },
        sections: {
          sample: {
            roles: {},
            clients: [],
          },
        },
      },
      pagesDraft: [],
    };
  },
  watch: {
    allPages: {
      immediate: true,
      handler(val) {
        console.log("pages", val);
        this.pagesDraft = [...val].sort((a, b) => a.weight - b.weight);
      },
      deep: true,
    },
  },
  components: {
    draggable,
    // Roles,
    VJsoneditor,
    RoleSelect
  },
  computed: {
    ...mapGetters(["allPages"]),
  },
  methods: {
    async reorder() {
      var batch = db.batch();

      this.pagesDraft.forEach((page, index) => {
        console.log(JSON.stringify({page, index}))
        page.weight = index;
        console.log(JSON.stringify({page, index}))
        let pageDoc = db.collection("pages").doc(page.id);
        batch.update(pageDoc, {
          weight: index,
        });
      });
      await batch.commit();
    },
    edit(page) {
      this.page = JSON.parse(JSON.stringify(page));
      this.page = JSON.parse(JSON.stringify(page));
      // this.page.sections = JSON.stringify(page.sections)
    },
    reset() {
      this.$refs.pageForm.reset();
      //   this.page = {
      //   id: '',
      //   name: '',
      //   icon: '',
      //   path: '',
      //   weight: 0,
      //   roles: [],
      // }
    },
    async createPage() {
      let id = this.page.id || undefined;
      this.page.weight = id ? this.page.weight : this.pages.length;
      this.page.updated = this.page.updated || new Date();
      // this.page.sections = this.page.sections ? JSON.parse(this.page.sections) : {}
      let pageDoc = await db.collection("pages").doc(id);
      id = pageDoc.id;
      this.page.id = id;
      await pageDoc.set(this.page);
      this.$refs.pageForm.reset();
      console.log(this.$refs.pageForm);
    },
  },
};
</script>

<style>
</style>