<template>
  <div class="d-flex">
    <v-card class="flex-shrink-1 mr-2">
      <v-card-text>
        <v-list dense>
          <v-subheader>Imports</v-subheader>
          <v-list-item-group>
            <v-list-item
              v-for="dataset in importDatasets"
              :key="dataset.id"
              class="pl-0"
            >
              <v-list-item-icon class="mr-1">
                <v-icon>mdi-chart-box</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="pa-0 ma-0">
                <v-list-item-title>
                  {{ dataset.name }}
                </v-list-item-title>
              </v-list-item-content>
              <v-btn icon @click="edit(dataset)">
                <v-icon small>mdi-note-edit-outline</v-icon>
              </v-btn>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
    </v-card>
    <v-card class="flex-grow-1">
      <v-card-text>
        <v-form ref="importForm" dense>
          <v-text-field
            dense
            label="Import Name"
            v-model.trim="importDataset.name"
          ></v-text-field>
          <v-textarea
            dense
            label="Description"
            rows="1"
            v-model="importDataset.description"
          ></v-textarea>
          <div class="d-flex">
            <v-combobox
              dense
              class="mr-5"
              label="Category"
              :items="categories"
              v-model.trim="importDataset.category"
            >
            </v-combobox>

            <v-text-field
              class="mr-5"
              label="Database Table"
              dense
              v-model.trim="importDataset.table"
            ></v-text-field>
          </div>

          <RoleSelect multiple v-model="importDataset.access.roles" />
          <ClientSelect multiple v-model="importDataset.access.clients" />
          <v-file-input
            label="Sample File"
            outlined
            dense
            @change="importSample"
          ></v-file-input>
          <div class="d-flex">
            <v-card class="flex-grow-1 ml-3">
              <v-card-text>
                <draggable v-model="importDataset.headers" @end="reorder">
                  <v-toolbar
                    dense
                    v-for="header in importDataset.headers"
                    :key="header.id"
                    flat
                    :color="
                      header.weight % 2 == 0 ? 'grey lighten-3' : undefined
                    "
                  >
                    <span class="mr-2">{{ header.weight }}) </span>

                    <label class="pr-2">Label:</label>
                    <v-text-field
                      class="flex-grow-1"
                      hide-details
                      single-line
                      v-model="header.text"
                    ></v-text-field>
                    <label class="pr-2">Value:</label>
                    <v-text-field
                      class="flex-grow-1"
                      hide-details
                      single-line
                      v-model="header.value"
                    ></v-text-field>
                    <label class="pr-2">Default:</label>
                    <v-text-field
                      class="flex-grow-1"
                      hide-details
                      single-line
                      v-model="header.defaultValue"
                    ></v-text-field>
                    <v-btn
                      color="red"
                      icon
                      small
                      @click="
                        importDataset.headers = importDataset.headers.filter(
                          (item) => item != header
                        );
                        reorder();
                      "
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <!-- </v-toolbar-items> -->
                  </v-toolbar>
                </draggable>
              </v-card-text>
            </v-card>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="clear">Cancel</v-btn>
        <v-btn
          v-if="importDataset.id"
          color="red"
          @click="deleteImportDataset"
          icon
        >
          <v-icon> mdi-delete </v-icon>
        </v-btn>
        <v-spacer />
        <v-btn color="primary" @click="saveImportDataset">Save</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import RoleSelect from "../../components/fields/RoleSelect.vue";
import ClientSelect from "../../components/fields/ClientSelect.vue";
import draggable from "vuedraggable";
import firebase from "../../firebase";
const db = firebase.firestore();

const defaultDataset = {
  id: "1",
  name: null,
  description: null,
  access: {
    clients: [],
    roles: {},
  },
  table: null,
  headers: [
  ],
  category: "",
};

export default {
  data() {
    return {
      filterSelector: "",
      importDataset: { ...defaultDataset },
    };
  },
  computed: {
    ...mapState(["importDatasets"]),
    categories() {
      return [
        ...new Set(
          (this.importDatasets || []).map((dataset) => dataset.category)
        ),
      ];
    },
  },
  components: {
    RoleSelect,
    ClientSelect,
    draggable,
  },
  methods: {
    async getImports() {},
    async importSample(evt) {
      console.log({ evt });
      var reader = new FileReader();

      reader.onload = (e) => {
        console.log("ON LOAD", e, e.target.result);
        let headers = e.target.result.split("\n")[0].split(",");
        this.importDataset.headers = headers.map((header, index) => {
          return {
            text: header,
            value: header.replace(/ /g, "_").toLowerCase(),
            weight: index,
          };
        });
      };
      if (evt) {
        console.log("GO", { evt });
        reader.readAsText(evt);
      }
    },
    async deleteImportDataset() {
      if (!this.importDataset.id) {
        return;
      }
      let doc = await db
        .collection("importDatasets")
        .doc(this.importDataset.id);
      await doc.delete();
      this.clear();
    },
    edit(importDataset) {
      this.importDataset = {
        ...JSON.parse(JSON.stringify(defaultDataset)),
        ...JSON.parse(JSON.stringify(importDataset)),
      };
    },
    reorder() {
      console.log("REORDER");
      this.importDataset.headers.forEach((filter, index) => {
        console.log(filter, index);
        filter.weight = index;
      });
    },
    async saveImportDataset() {
      console.log("SAVE IMPORT", this.importDataset);
      let id = this.importDataset.id || this.importDataset.name;
      this.importDataset.created = this.importDataset.created || new Date();
      this.importDataset.updated = this.importDataset.updated || new Date();
      // this.page.sections = this.page.sections ? JSON.parse(this.page.sections) : {}
      console.log("PRE DOC", id);
      let doc = await db.collection("importDatasets").doc(id);
      console.log(doc);
      this.importDataset.id = doc.id;
      await doc.set(this.importDataset);
      this.clear();
    },
    clear() {
      this.importDataset = JSON.parse(JSON.stringify(defaultDataset));
      this.$refs.importForm.reset();
    },
  },
};
</script>
