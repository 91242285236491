<template>
  <div class="d-flex">
    <v-card class="flex-shrink-1 mr-2" >
      <v-card-text>
        <v-list dense height="100%"
         style="max-height: calc(100vh - 150px)"
       class="overflow-y-auto"
        >
          <template v-for="(collection,key) in bookmarks" >
          <v-list-item-group :key="key">
          <v-subheader>{{key}}</v-subheader>
            <draggable v-model="collection.bookmarks" @end="reorder(key)">
              <v-list-item v-for="item in collection.bookmarks" :key="item.url"  class="pl-0">
                <v-list-item-icon class="mr-1">
                  <img v-if="item.image" :src="item.image" />
                  <v-icon v-else>{{item.icon || 'mdi-web'}}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <span>{{item.title}}</span>
                  </v-list-item-title>
                </v-list-item-content>
                  <v-btn icon @click="edit(item)">
                    <v-icon small>mdi-book-edit</v-icon>
                  </v-btn>
              </v-list-item>
            </draggable>
          </v-list-item-group>
          </template>
        </v-list>
      </v-card-text>
    </v-card>
    <div class="flex-grow-1 flex-column">


 <v-card class="flex-grow-1 mb-5">
   <v-card-title>
     <span v-if="bookmark.created">Update</span><span v-else>Add</span>
      &nbsp;<span>Bookmark</span>
   </v-card-title>
      <v-card-text>
        <v-form ref="bookmarkForm" v-model="valid">
          <v-text-field
            dense
            label="Title"
            v-model="bookmark.title"
            required
            autofocus
          ></v-text-field>
          <v-text-field
            label="url"
            dense
            @change="preloadImage"
            v-model="bookmark.url"
            required
          ></v-text-field>
          <v-text-field
            label="Icon"
            dense
            v-model="bookmark.icon"
            required
          ></v-text-field>


          <v-select
          dense
          label="Collection"
          :items="collections"
          v-model="bookmark.collection"
          required
          ></v-select>
          <!-- <v-combobox
            dense
            v-model="bookmark.collection"
            :filter="filter"
            :hide-no-data="!search"
            :items="groups"
            :search-input.sync="search"
            hide-selected
            clearable
            deletable-chips
            label="Collection"
          >
            <template v-slot:no-data>
              <v-list-item>
                <span class="subheading">Create: </span>
                <v-chip label small color="green">
                  {{ search }}
                </v-chip>
              </v-list-item>
            </template>
            <template v-slot:selection="{ attrs, item, selected }">
              <v-chip
                v-if="item"
                v-bind="attrs"
                :input-value="selected"
                label
                small
              >
                <span class="pr-2">
                  {{ item }}
                </span>
                <v-icon small @click="bookmark.collection = null">
                  $delete
                </v-icon>
              </v-chip>
            </template>
          </v-combobox> -->

          <v-textarea
            dense
            label="Description"
            v-model="bookmark.description"
            rows="2"
          ></v-textarea>
          <div class="d-flex">
            <v-text-field
              label="image"
              dense
              v-model="bookmark.image"
              class="flex-grow-1"
            ></v-text-field>
            <img
              v-if="bookmark.image"
              height="30"
              :src="bookmark.image"
              :alt="bookmark.title"
            />
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-checkbox
          label="IE Only"
          v-model="bookmark.ieOnly"
          dense
          class="mr-5"
        ></v-checkbox>

        <v-checkbox
          label="Disabled"
          v-model="bookmark.disabled"
          class="mr-5"
          dense
        ></v-checkbox>
        <v-checkbox
          label="VDI Only"
          v-model="bookmark.vdiOnly"
          dense
        ></v-checkbox>
        <v-spacer></v-spacer>

        <v-btn @click="validate" text> validate </v-btn>
        <v-btn @click="reset" text> Cancel </v-btn>
        <v-btn @click="addBookmark" color="primary">
          {{ bookmark.created ? "Update" : "Add" }} Bookmark
        </v-btn>
      </v-card-actions>
    </v-card>


<v-card>
  <v-card-title>
    Add Collection
  </v-card-title>
  <v-card-text>
    
<v-form ref="collectionForm">
  <v-text-field label="New Collection" v-model="collection.name"></v-text-field>
            <RoleSelect multiple v-model="collection.access.roles" />
            <ClientSelect multiple v-model="collection.access.clients" />

</v-form>
  </v-card-text>
  <v-card-actions>
    <v-spacer></v-spacer>
    <v-btn color="primary" @click="saveCollection()">Add Collection</v-btn>
  </v-card-actions>
</v-card>
      
    </div>
   
    <div>

    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import draggable from "vuedraggable";
import RoleSelect from "../../components/fields/RoleSelect";
import ClientSelect from "../../components/fields/ClientSelect";

import firebase from "../../firebase";
const db = firebase.firestore();

const preloadImage = (src) =>
  new Promise((resolve) => {
    const image = new Image();
    image.onload = resolve;
    image.onerror = resolve;
    image.src = src;
  });

const defaultCollection = {
 name: '',
        created: null,
        updated: null,
        access: {
          roles: {
            ops:true,
            ba:true,
          },
          clients:[],
        },
        bookmarks: []
}

const defaultBookmark = {
        title: "",
        url: "",
        image: "",
        ieOnly: false,
        weight: null,
        description: "",
        collection: "",
        disabled: false,
        created: null,
        updated: null,
        icon: null,
        vdiOnly: true
      }

export default {
  data() {
    return {
      collection: JSON.parse(JSON.stringify(defaultCollection)),
      valid: true,
      activator: null,
      attach: null,
      search: "",
      menu: false,

      bookmarksDraft: [],
      bookmark: JSON.parse(JSON.stringify(defaultBookmark)),
    };
  },
  components: {
    RoleSelect,
    draggable,
    ClientSelect
  },
  methods: {
    edit(bookmark) {
      this.bookmark = JSON.parse(JSON.stringify(bookmark));
    },
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1
      );
    },

    async reorder(key) {
      this.bookmarks[key].bookmarks.forEach((bookmark,index)=>{
        bookmark.weight = index
      })
      this.saveCollection(key)
      // var batch = db.batch();

      // this.bookmarksDraft.forEach((item, index) => {
      //   item.weight = index;
      //   let itemDoc = db.collection("bookmarks").doc(item.id);
      //   batch.update(itemDoc, {
      //     weight: index,
      //   });
      // });
      // await batch.commit();
    },
    async preloadImage() {
      console.log("PRELOAD");
      let el = document.createElement("a");
      el.href = this.bookmark.url;
      let url = `${el.protocol}/${el.host}/favicon.ico`;
      let result = await preloadImage(url);
      if (result.type === "load") {
        this.bookmark.image = url;
      }
    },
    async saveCollection(key){
      key = key || this.collection.name
      if (!key){
        return
      }
      let collection = this.bookmarks[key] || this.collection
      collection.created = collection.created || new Date()
      collection.updated = new Date()
      let doc = await db.collection("bookmarks").doc(key);
      await doc.set(collection);
      this.$refs.collectionForm.reset();
      this.collection = JSON.parse(JSON.stringify(defaultCollection))
},
    async addBookmark() {
      let collection = this.bookmarks[this.bookmark.collection]
        if (this.bookmark.weight === null){
        this.bookmark.created = new Date()
        this.bookmark.weight = collection?.bookmarks?.length || 0;
        collection.bookmarks.push(this.bookmark)
      } else{
        console.log('SPLUCE', JSON.stringify(collection), this.bookmark)
        collection.bookmarks.splice(this.bookmark.weight,1,this.bookmark)
      }

      // let id = this.bookmark.id || undefined;
      // let doc = await db.collection("bookmarks").doc(id);
      // this.bookmark.id = doc.id;
      // await doc.set(this.bookmark);
      // this.$refs.bookmarkForm.reset();
      console.log(collection,this.bookmarks ,this.bookmark.collection ,this.bookmarks[this.bookmark.collection] )
    
      this.bookmark.updated = new Date()
      collection.updated = new Date()

      let doc = await db.collection("bookmarks").doc(this.bookmark.collection);
      await doc.set(collection);
      this.reset();
    },
    validate() {
      console.log("valide", this.$refs.bookmarkForm.validate());
    },
    reset() {
      this.bookmark = JSON.parse(JSON.stringify(defaultBookmark))
      this.$refs.bookmarkForm.reset();
    },
  },

  watch: {
    // bookmarks: {
    //   immediate: true,
    //   handler(val) {
    //     console.log("bookmarks", val);
    //     this.bookmarksDraft = [...val].sort((a, b) => a.weight - b.weight);
    //   },
    //   deep: true,
    // },
    "bookmark.url": {
      async handler(val) {
        if (!val) {
          this.bookmark.image = "";
        } else {
          this.preloadImage();
        }
        // let el = document.createElement("a");
        // el.href = val;
        // console.log({ el });
        // let url = `${el.protocol}/${el.host}/favicon.ico`;
        // let result = await preloadImage(url);
        // if (result.type === "load") {
        //   this.bookmark.image = url;
        // }
      },
      immediate: true,
    },
  },
  computed: {
    collections() {
      return Object.keys(this.bookmarks);
    },
    ...mapState(["bookmarks"]),
  },
};
</script>

<style>
</style>