<template>
<div>
  <v-select
    label="Roles"
    :items="roles"
    :multiple="multiple"
    dense
    v-model="selected"
    @change="change"
    hide-selected
    clearable
    deletable-chips
  >
    <template v-slot:selection="{ item }">
      <v-chip x-small label  color="primary" class="pr-0" @click.stop=""
>
        <span>{{ item }}</span>
        <v-btn x-small dark icon @click.stop="selected=selected.filter(i=>i!=item)"  >
      <v-icon >
        mdi-close-thick
      </v-icon>
      </v-btn>
      </v-chip>
    </template>
  </v-select>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      selected: null,
    };
  },
  props: ["multiple","value"],
  //   props: {
  //     multiple: {
  //       default: true,
  //       type: Boolean,
  //     },
  //   },
  watch:{value:{
    immediate: true,
    handler(val) {
      console.log({ val });
      this.selected = val ? Object.keys(val) : [];
    },}
  },
  computed: {
    ...mapState(["permissions"]),
    roles() {
      return [
        ...new Set(
          (this.permissions.mapping || []).map((permission) => permission.role)
        ),
      ].filter(role=>role != 'dev')
    },
  },
    methods: {
      change() {
        console.log("change");
        let result = this.selected.reduce((obj,role)=>{
          obj[role]=true
          console.log(obj)
          return obj
          },{})
        result.dev = true
          console.log({result})
        this.$emit("input", result);
      },
    },
};
</script>

<style>
</style>