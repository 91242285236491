export default [
    "Virtual Desktop - East",
    "Virtual Desktop - West",
    "BrightPattern",
    "VIPdesk",
    "AWS",
    "Google GCP",
    "Voice Analytics/QM",
    "Zendesk",
    "Alvaria WFM",
    ]